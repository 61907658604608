import { createApp } from 'vue'
import App from './App.vue'


import router from './router'
import store from './store';

import "bootstrap"

const app = createApp(App).use(router)
app.use(router)
app.use(store);
app.mount('#app')


// Dispatch the updateScreenSize action whenever the window is resized or zoomed
window.addEventListener('resize', () => {
    store.dispatch('updateScreenSize');
  });
  
  // Optionally, you can also listen for the zoom event
  window.addEventListener('zoom', () => {
    store.dispatch('updateScreenSize');
  });


  