const HomeView = () => import("../views/HomeView.vue");

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Macro Holdings Australia",
      description:
        "Macro Holdings Australia: Your premier source for bulk wine and rural supplies nationwide. With over 25 years of industry expertise, we specialize in sourcing quality products at competitive prices, ensuring timely delivery and superior customer satisfaction. From importation to logistics, trust us for all your bulk sourcing needs",
    },
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () => import("../views/AboutUsView.vue"),
    meta: {
      title: "About Us - Macro Australia",
      description:
        "Macro Holdings Australia: Your trusted specialists in importation and bulk supply services for both the wine and rural industries across Australia. With a seasoned team boasting over 25 years of experience, we are committed to delivering competitive rates and superior products. Whether it's custom-made glassware for the wine sector or essential fencing items for rural needs, our extensive network and stringent quality control ensure client satisfaction every time. Contact us with your requirements, and let us handle the sourcing and logistics for you.",
    },
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () => import("../views/ContactUsView.vue"),
    meta: {
      title: "Contact Us - Macro Australia",
      description:
        "Contact Macro Holdings Australia for all your bulk supply needs in the wine and rural industries. Our experienced team is ready to assist you with sourcing quality products, arranging logistics, and providing competitive rates. Whether you have specific requirements or need assistance with your order, we're here to help. Reach out to us today to discuss how we can fulfill your bulk supply needs efficiently and effectively.",
    },
  },
  {
    path: "/enquire",
    name: "enquire",
    component: () => import("../views/EnquireNowView.vue"),
    meta: {
      title: "Enquire - Macro Australia",
      description:
        "Connect with Macro Holdings Australia through our Enquiry page to streamline your bulk supply needs. Whether you're seeking tailored solutions for the wine industry or essential products for rural sectors, our dedicated team stands ready to assist. Utilize this page to reach out for inquiries, place orders, or discuss customized requirements.",
    },
  },
  {
    path: "/products",
    name: "products",
    component: () => import("../views/AllProductListView.vue"),
    meta: {
      title: "Products - Macro Australia",
      description:
        "Explore our diverse range of products catering to both the wine and rural industries. Discover quality solutions tailored to your needs on our Products page.",
    },
  },
  {
    path: "/products/rural-industry",
    name: "rural-industry-products",
    component: () => import("../views/ProductListViewRural.vue"),
    meta: {
      title: "Rural Industry - Macro Australia",
      description:
        "Explore our comprehensive range of rural industry supplies. From bulk fencing items to steel sections and cattle rails, we provide essential solutions for rural operations. With a focus on cost optimization and quality assurance, we ensure value and reliability in every product. Contact us today to fulfill your rural industry needs effortlessly.",
    },
  },
  {
    path: "/products/wine-industry",
    name: "wine-industry-products",
    component: () => import("../views/ProductListView.vue"),
    meta: {
      title: "Wine Industry - Macro Australia",
      description:
        "Explore our premium wine industry supplies. From custom glassware to specialized fermenters and packaging solutions. Discover tailored solutions for your vineyard or winery needs today.",
    },
  },
  {
    path: "/products/wine-industry/:product",
    name: "wine-industry-product",
    component: () => import("../views/ProductView.vue"),
    meta: {
      title: "Products - Macro Australia",
      description:
        "Discover our premium wine industry supplies, featuring custom glassware, specialized fermenters, and packaging solutions.",
    },
  },
  {
    path: "/products/product-not-found",
    name: "product-not-found",
    component: () => import("../views/NotFoundView.vue"),
    meta: {
      title: "Products Not Found - Macro Australia",
      description: "Product not found page",
    },
  },
  {
    path: "/form_complete",
    name: "form_complete",
    component: () => import("../views/FormSubmittedView.vue"),
    meta: {
      title: "Submission Complete - Macro Australia",
      description: "Form Submission Page",
    },
  },
];

module.exports = routes;
