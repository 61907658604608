const { createRouter, createWebHistory } = require("vue-router");
const { nextTick } = require("vue");
const routes = require("./routes");

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash.length > 0) {
      return new Promise((resolve) => {
        nextTick(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            resolve({ el: to.hash, behavior: "smooth" });
          }
        });
      });
    }
    return { top: 0 }; // Scroll to the top of the page
  },
});

// Function to format the product name
function formatProductName(product) {
  return product
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

// Global navigation guard for setting the document title
router.beforeEach((to, from, next) => {
  // eslint-disable-next-line no-unused-vars
  const { title, description } = to.meta;
  if (to.name === "wine-industry-product" && to.params.product) {
    const formattedProductName = formatProductName(to.params.product);
    document.title = `${formattedProductName} - Macro Australia`;
  } else if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "Macro Holdings Australia";
  }

  const defaultDescription =
    "Macro Holdings Australia: Your premier source for bulk wine and rural supplies nationwide. With over 25 years of industry expertise, we specialize in sourcing quality products at competitive prices, ensuring timely delivery and superior customer satisfaction. From importation to logistics, trust us for all your bulk sourcing needs";
  const descriptionElement = document.querySelector(
    'head meta[name="description"]'
  );
  descriptionElement.setAttribute("content", description || defaultDescription);
  next();
});
module.exports = router;
