<template>
  <router-view class="h-100" />
</template>
<script>
export default {
  name: "APP",
  mounted() {
    this.$store.dispatch("updateScreenSize");
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
