import { createStore } from "vuex";


export default createStore({
    state: {
        screenSize: {
            isMobile: false,
            isSmallScreen: false,
            isLargeScreen: false,
            isExtraLargeScreen: false
        }
    },
    mutations: {
        setScreenSize(state, size) {
          state.screenSize = size;
        }
      },
      actions: {
        updateScreenSize({ commit }) {
          const width = window.innerWidth;
          var size = {
            isExtraSmallScreen: width < 576,
            isSmallScreen: width >= 576 && width < 768,
            isMediumScreen: width >= 768 && width < 992,
            isLargeScreen: width >= 992 && width < 1200,
            isExtraLargeScreen: width >= 1200 && width < 1400,
            isExtraExtraLargeScreen: width >= 1400,
          };

          size.isMobile = size.isExtraSmallScreen || size.isSmallScreen;
          size.isSmallMedium = size.isExtraSmallScreen || size.isSmallScreen || size.isMediumScreen;  
          commit('setScreenSize', size);
        }
      },
      getters: {
        screenSize(state) {
          return state.screenSize;
        }
      }
  });

